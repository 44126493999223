<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		append-to-body
		width="60%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.name }">
						<label>Nombre</label>
						<el-input v-model="form.name" :readonly="form.id ? true : false"></el-input>
						<small
							class="error--text"
							v-if="errors.name"
							v-text="errors.name[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.title }">
						<label>Descripción</label>
						<el-input v-model="form.title"></el-input>
						<small
							class="error--text"
							v-if="errors.title"
							v-text="errors.title[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<el-divider>Permisos</el-divider>
			<v-row>
				<v-col
					cols="12"
					xl="6"
					lg="12"
					md="12"
					sm="12"
					v-for="(ability, index) in form.permissions"
					:key="index"
				>
					<el-checkbox v-model="ability.checked" :disabled="form.locked">{{
						ability.title
					}}</el-checkbox>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'roles',
			permissions: [],
			errors: {},
			form: {},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				name: null,
				title: null,
				guard_name: 'web',
				permissions: [],
			};
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar rol' : 'Nuevo rol';
			await this.initForm();
			if (!this.recordId) await this.getTables();
			if (this.recordId) {
				await this.$http
					.get(`/${this.resource}/record/${this.recordId}`)
					.then((response) => {
						this.form = response.data.data;
					});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.form.permissions = response.data.permissions;
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
					this.loading = false;
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
